export function loadGoogleMapsApi(key, language, region) {
    return new Promise((resolve, reject) => {
        if (typeof google !== 'undefined' && google.maps) {
            console.log("Google Maps API già caricata.");
            resolve();
        } else {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=${language}&region=${region}`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                console.log("Google Maps API caricata con successo.");
                resolve();
            };
            script.onerror = () => {
                console.error("Errore nel caricamento della Google Maps API.");
                reject(new Error('Failed to load Google Maps API'));
            };
            document.head.appendChild(script);
        }
    });
}