<template>
  <v-text-field
      v-model="search"

      ref="autocompleteInput"
      hide-details
      class="input-style font-size-input text-light-input placeholder-light input-icon"
      outlined
      color="pink"
      flat
      height="53"
  ></v-text-field>
</template>

<script>
import {loadGoogleMapsApi} from '@/util/loadGoogleMapsApi';

export default {
  data() {
    return {
      search: '',
      autocomplete: null,
    };
  },
  mounted() {
    loadGoogleMapsApi(
        //  AIzaSyAX5CDkFKyK9cfNQgdNUTGCmV1iNJkZ6Mk
        'AIzaSyAw1SFJEdbTp8WaVPbWECeUbzB8kIIyWbs',
        'it',
        'IT'
    ).then(() => {
      this.setupAutocomplete();
    }).catch(error => {
      console.error(error);
    });
  },
  methods: {
    onInput() {
      // Logica da eseguire quando il testo cambia
    },
    setupAutocomplete() {
      const input = this.$refs.autocompleteInput?.$el?.querySelector('input');
      if (!input) {
        console.error("Input non trovato.");
        return;
      }

      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input);

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        this.onPlaceChanged(place);
      });

      console.log("Autocomplete configurato.");
    },
    onPlaceChanged(place) {
      console.log("Place selezionato:", place);
      this.$emit('place-selected', place);
    },
    setPlace(place) {
      this.place = place;
    },
  },
};
</script>


