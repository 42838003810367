<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class=" font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
      >Aggiungi Vino
      </v-btn>
    </template>

    <v-card class="card-shadow">
      <v-card-title class=" card-border-bottom" style="background-color: #ECEFF1">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Aggiungi Vino da Winemaker
          </span>
      </v-card-title>
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <v-row>
            <!-- *********** NOME VINO *********** -->
            <v-col cols="12">
              <v-text-field
                  label="Nome"
                  v-model="payload.name"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  color="pink"
                  flat
                  height="43"
              ></v-text-field>
            </v-col>
            <!-- *********** GRADAZIONE *********** -->
            <v-col cols="6">
              <v-text-field
                  label="Gradazione"
                  v-model="payload.gradation"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  color="pink"
                  flat
                  height="43"
              ></v-text-field>
            </v-col>
            <!-- *********** VINIFICATION *********** -->
            <v-col cols="6">
              <v-text-field
                  label="Vinificazione"
                  v-model="payload.vinification"
                  hide-details
                  color="pink"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  height="43"
              ></v-text-field>
            </v-col>
            <!-- *********** VINTAGE *********** -->
            <v-col cols="6">
              <v-text-field
                  ref="vintage"
                  label="Annata"
                  color="pink"
                  v-model="payload.vintage"
                  hint="Campo Obbligatorio"
                  :rules="[isRequired]"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  height="43"
              ></v-text-field>
            </v-col>
            <!-- *********** MAGNUM *********** -->
            <v-col cols="6">
              <v-checkbox
                  label="Magnum"
                  v-model="payload.magnum"
                  :value="payload.magnumCheck"
                  color="pink"
                  hide-details
              ></v-checkbox>
            </v-col>
            <!-- *********** TYPE *********** -->
            <v-col cols="6">
              <v-autocomplete
                  label="Tipo"
                  :search-input.sync="search"
                  v-model="payload.type"
                  :menu-props="{ top: false, offsetY: true }"
                  :items="tipiVino"
                  item-text="name"
                  item-value="id"
                  required
                  chips
                  color="pink"
              ></v-autocomplete>
            </v-col>
            <!-- *********** TYPE GRAPES *********** -->
            <v-col cols="6">
              <v-autocomplete
                  v-model="payload.type_grapes"
                  :items="tipiUva"
                  :menu-props="{ top: false, offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Uva"
                  data-vv-name="select"
                  multiple
                  chips
                  color="pink"
                  required
              ></v-autocomplete>
            </v-col>
            <!-- *********** NOTE *********** -->
            <v-col cols="12">
              <v-textarea
                  v-model="payload.note"
                  item-text="note"
                  label="Note"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
          class="card-padding d-flex justify-end"
          style="background-color: #ECEFF1">
        <v-btn
            @click="close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-outline-secondary
              bg-transparent
              py-3
              px-6
            "
        >Cancella
        </v-btn>
        <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
        >Salva
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import wineServices from "@/services/wine.services";
import typeAndtypegrapesService from "@/services/typeAndtypegrapes.service";

export default {
  props: {
    winemaker: Number
  },
  mounted() {
    this.getTipoVino(),
        this.getTipoUva()
  },
  data() {
    return {
      dialog: false,
      payload: {},
      search: '',
      tipiVino: [],
      tipiUva: [],
    }
  },
  methods: {
    getTipoVino() {
      typeAndtypegrapesService.getTipi().then(resp => {
        this.tipiVino = resp.data.results
      }).catch().finally()
    },
    getTipoUva() {
      const params = {
        page: 1,
        type: ''
      }
      typeAndtypegrapesService.getTipiUva(params).then(resp => {
        this.tipiUva = resp.data.results
      }).catch().finally()
    },
    save() {
      const isValid = this.$refs.vintage.validate();
      if (!isValid) {
        this.$dialog.notify.error("Compila tutti i campi obbligatori", {color: "red"});
        return;
      }
      this.payload.winemaker = this.winemaker
      wineServices.newVino(this.payload).then(() => {
        this.payload = {}
        this.$dialog.notify.success('Vino Aggiunto Correttamente!', {color: 'green'})
      }).catch(error => {
        this.$dialog.notify.error('Errore nella creazione del nuovo vino', {color: 'red'})
        console.error(error)
      }).finally(() => {
        this.$emit('refreshWine')
        this.dialog = false
      })
    },
    isRequired(value) {
      return !!value || 'Questo campo è obbligatorio';
    },
    close() {
      this.dialog = false
    }
  }
}
</script>