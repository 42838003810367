<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="pink"
      >
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Elenco Produttori
          <v-chip
              class="ma-2"
              color="green"
              outlined
          >
            Totale Produttori: {{ conteggioProduttori }}
          </v-chip>
          <v-dialog v-model="dialog" max-width="1200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
                      >Aggiungi Produttore
                      </v-btn>
                    </template>

                    <add-produttore
                        @close="dialog = false"
                        @refresh="getProduttori"
                    ></add-produttore>
                  </v-dialog>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            @click:row="rowClick"
            item-key="id"
            v-model="selectedItem"
            :headers="headers"
            :items="produttori"
            single-select
            dense
            loading
            loading-text="Caricamento pagina in corso..."
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      hide-details
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      "
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="filter.ricerca"
                      placeholder="Ricerca Per Nome/Regione/Nazione"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                          color="#adb5bd"
                          size="16"
                          class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- Delete Dialog-->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                  >Vuoi eliminare questo Produttore?
                  </v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeDelete"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      "
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        @click="deleteItemConfirm"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center ms-2 cursor-pointer truncate">
              <v-tooltip color="black" top lazy>
                <template #activator="{ on }">
                  <span class="text-sm font-weight-bolder text-pink" v-on="on">
                    {{ item.name }}
                  </span>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.distributor`]="{ item }">
            <div class="d-flex align-center ms-2 cursor-pointer truncate">
              <v-tooltip color="black" top lazy>
                <template #activator="{ on }">
                  <span class="text-sm font-weight-bolder text-body" v-on="on">
                   {{ item.distributors ? item.distributors.name : '' }}
                  </span>
                </template>
                {{ item.distributors ? item.distributors.name : 'Nessun distributore' }}
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.is_direct`]="{ item }">
            <!-- <span > -->
            <span v-if="item.is_direct===true">
                <v-icon size="20" class="material-icons-round mt-n2" color="#345543">check</v-icon>
            </span>
            <span v-else></span>
          </template>
          <template v-slot:[`item.how_many_wines`]="{ item }">
            <div class="d-flex align-center ms-2">
              <v-chip color="indigo" small dark>
                {{ item.how_many_wines }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.region`]="{ item }">
            <span class="text-sm font-weight-normal text-body">{{ item.region }}</span>
          </template>
          <template v-slot:[`item.country`]="{ item }">
            <span class="text-sm font-weight-normal text-body">{{ item.country }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- Contenitore Flex per disporre i pulsanti orizzontalmente -->
            <div class="d-flex align-center">
              <v-btn
                  @click="editItem(item)"
                  small
                  icon
                  elevation="0"
                  :ripple="false"
                  tile
              >
                <v-icon size="14">edit</v-icon>
              </v-btn>
              <v-btn
                  @click="deleteItem(item)"
                  icon
                  color="red"
              >
                <v-icon size="14">close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="getPageNumber"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog
        v-model="dialogEdit"
        max-width="800">
      <edit-produttore
          v-if="dialogEdit"
          :item="dialogEditItem"
          @close="dialogEdit = false"
          @refresh="getProduttori"
      ></edit-produttore>
    </v-dialog>
  </div>
</template>
<script>
import users from "./Users";
import winemakerService from '@/services/winemaker.service'
import {eventBus} from '@/event-bus.js';
import AddProduttore from "@/views/Pages/Winemaker/AddProduttore.vue";
import EditProduttore from "@/views/Pages/Winemaker/EditProduttore.vue";


export default {
  name: "paginated-tables",
  components: {
    EditProduttore,
    AddProduttore,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      selectedItem: [0],
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      users,
      // -------------------
      // componentKey: 0,
      // geocoder: null,
      produttori: [],
      itemId: "",
      conteggioProduttori: "",
      loading: false,
      items: [],
      distribuzione: [
        'Directly',
        'Large Distribution'
      ],
      // -------------------
      search: "",
      // editedIndex: -1,

      headers: [
        {
          text: "Produttore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class:
              "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Distributore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "distributor",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Regione",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "region",
          class:
              "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Nazione",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "country",
          class:
              "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Vini",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "how_many_wines",
          class: "border-bottom ps-6",
        },
        {
          text: "Actions",
          value: "actions",
          cellClass: "border-bottom text-right",
          sortable: false,
          class: "border-bottom text-right",
        },
      ],
      progress: false,
      openFiltri: false,
      filter: {
        ricerca: '',
        ricercaNazione: "",
        ricercaRegione: ""
      },
      // Dialog
      addProduttore: false,
      openDialog: false,
      // formTitle:""
      getWineMakerWithDistributor: false,
      dialogAdd: false,
      dialogEditItem: {},
      dialogEdit: false
    };
  },
  mounted() {
    this.getProduttori()
    this.getPageNumber()
    eventBus.$on('googleInit', () => {
      // this.initMapAutocomplete();
    });
  },
  methods: {
    filtraIsDirect() {
      const params = {
        winemaker_with_distributor: this.getWineMakerWithDistributor,
        store: this.$store.state.auth.user.store_id
      }
      winemakerService.getProduttori(params)
          .then((resp) => {
            this.produttori = resp.data.results
            this.produttori.sort()
            this.conteggioProduttori = resp.data.count
            this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
            this.progress = false
          })
    },
    editButton() {
      this.$refs.childComponentRef.openDialog('edit');
    },
    addButton() {
      this.$refs.childComponentRef.openDialog('add');
    },
    rowClick: function (item, row) {
      row.select(true);
      eventBus.$emit('produttoreId', item.id)
      eventBus.$emit('prodName', item.name)
    },

    removeDistroName() {
      // winemakerService.deleteDistroWinemaker(itemId, distroId).then(resp => {
      //   console.log('RESP ',resp.data.results)
      //   }
      // ).catch()
    },

    getLatitude(value) {
      // console.log('Latitudne: ', value)
      this.editedItem.lat = value

    },

    getLongitude(value) {
      // console.log('Longitudine: ', value)
      this.editedItem.long = value
    },
    getPageNumber(val) {
      this.page = val
      this.getProduttori()
    },

    getProduttori() {
      this.progress = true
      const params = {
        page: this.page,
        type: this.filter.type,
        search: this.filter.ricerca,
      }
      winemakerService.getProduttori(params)
          .then((resp) => {
            this.produttori = resp.data.results
            // this.editedItem = structuredClone(this.produttori)
            this.produttori.sort()
            this.conteggioProduttori = resp.data.count
            this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
            this.progress = false
          })
    },

    searchProduttori(page) {
      this.progress = true
      const params = {
        page: page,
        type: this.filter.type,
        search: this.filter.ricerca,
      }
      winemakerService.getProduttori(params)
          .then((resp) => {
            this.produttori = resp.data.results
            this.produttori.sort()
            this.conteggioProduttori = resp.data.count
            this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
            this.progress = false
          })
    },
    editItem(item) {
      console.log('PRODUTTORE: ', item)
      this.dialogEditItem = item
      this.dialogEdit = true;
    },
    deleteItem(item) {
      this.editedIndex = this.produttori.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const itemId = this.itemId
      this.produttori.splice(this.editedIndex, 1);
      winemakerService.deleteProduttore(itemId).then(() => {
        this.$dialog.message.success("Produttore eliminato correttamente", {
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
        this.closeDelete();
      });
    },
    close() {
      this.openDialog = false;
      this.addProduttore = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "filter.ricerca"(value) {
      if (value && value.length > 2) {
        this.searchProduttori();
      } else if (value.length === 0) {
        this.searchProduttori(1);
      }
    }
    // TRIGGER PRODUTTORE ID -> PRODUTTORE ITEMS
    // selectedItem(val) {
    //   // console.log(val[0].id)
    //   // this.checkListino(val)
    //   eventBus.$emit('produttoreId', val[0].id);
    // },

  },
// --------------------- PAGINAZIONE
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Produttore" : "Modifica Produttore";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
  },
};
</script>
<style scoped>
tr.v-data-table__selected {
  background: #dbecff !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
