<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">{{ icon }}</v-icon>
    </template>

    <v-card class="card-shadow">
      <v-card-title class=" card-border-bottom" style="background-color: #ECEFF1">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Aggiungi <span class="text-pink">{{ wine.name }}</span> al PriceList
          </span>
      </v-card-title>
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <details>
            <pre>
              {{ wine }}
            </pre>
          </details>

          Scegli PriceList:
          <v-select
              v-model="selectedPriceListItem"
              :items="pricelists"
              item-text="name"
              item-value="id"
              return-object
              hint="Required"
          >
          </v-select>

          <v-row v-show="selectedPriceListItem">
            <!-- *********** PREZZO DI ACQUISTO *********** -->
            <v-col cols="6">
              <v-text-field
                  label="Listino"
                  prefix="€"
                  type="number"
                  v-model="composePriceListItem.price_cost"
                  :rules="[rules.required]"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  height="43" required></v-text-field>
            </v-col>
            <!-- *********** PERCENTUALE DI SCONTO *********** -->
            <v-col cols="6">
              <v-text-field
                  label="Sconto"
                  prefix="%"
                  type="number"
                  value="0"
                  v-model="composePriceListItem.percentage_value"
                  hide-details
                  :rules="[isRequired]"
                  class="input-style font-size-input text-light-input text-pink placeholder-light input-icon"
                  dense flat
                  hint="Campo Calcolato"
                  height="43"
                  @input="calcolaCampoRiduzione"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-show="selectedPriceListItem">
            <!-- *********** PREZZO DI ACQUISTO DOPO LO SCONTO *********** -->
            <v-col cols="6">
              <v-text-field
                  ref="percentage"
                  label="Acquisto"
                  prefix="€" type="number"
                  v-model="composePriceListItem.price_reduce"
                  :rules="[rules.required]"
                  hide-details
                  class="input-style text-pink font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  hint="Campo Calcolato"
                  height="43" @input="calcolaCampoPercentuale"></v-text-field>
            </v-col>
            <!-- *********** PREZZO ASPORTO *********** -->
            <v-col cols="6">
              <v-text-field
                  label="Prezzo Asporto Consigliato"
                  type="number"
                  prefix="€"
                  hint="Campo Calcolato = Var + Costo * 2 * 1,23"
                  v-model="composePriceListItem.price_sell"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense flat
                  height="43"></v-text-field>
            </v-col>
            <!-- *********** PREZZO AL BICCHIERE *********** -->
            <v-col cols="6">
              <v-text-field
                  label="Prezzo al Calice Consigliato"
                  type="number"
                  prefix="€"
                  variant="indigo"
                  v-model="composePriceListItem.price_wine_glass"
                  hint="Campo Calcolato = Var + Costo / 2"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense flat
                  height="43">
              </v-text-field>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
          class="card-padding d-flex justify-end"
          style="background-color: #ECEFF1">
        <v-btn
            @click="closeDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-outline-secondary
              bg-transparent
              py-3
              px-6
            "
        >Cancella
        </v-btn>
        <v-btn
            @click="saveNewPriceListItem"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
        >Salva
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import listinoService from "@/services/listino.service";

export default {
  data() {
    return {
      dialog: false,
      pricelists: [],
      selectedPriceListItem: null,
      rules: {
        required: (value) => !!value || "Campo Obbligatorio.",
      },
      composePriceListItem: {
        wine: "",
        store: this.$store.state.auth.user.store_id,
        distributor: "",
        warehouse_name: "",
        distributor_name: "",
        price_list: "",
        price_cost: "", // Listino
        percentage_value: "",
        price_sell: "",
        price_reduce: "", // Prezzo di acquisto dopo lo sconto
        price_wine_glass: "",
        price_web: 0,
        quantity: "",
        sold_out: false,
      }
    };
  },
  methods: {
    isRequired(value) {
      return !!value || 'Questo campo è obbligatorio';
    },
    getPriceList() {
      console.log('item: ', this.wine)
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      listinoService.getListini(params).then(respy => {
        this.pricelists = respy.data.results
      }).catch().finally()
    },
    calcolaCampoRiduzione() {
      this.composePriceListItem.price_reduce =
          (this.composePriceListItem.price_cost -
              (this.composePriceListItem.price_cost * this.composePriceListItem.percentage_value) / 100).toFixed(2);
    },
    calcolaCampoPercentuale() {
      if (this.composePriceListItem.price_cost !== this.composePriceListItem.price_reduce) {
        this.composePriceListItem.percentage_value = ((
            (this.composePriceListItem.price_cost - this.composePriceListItem.price_reduce) /
            this.composePriceListItem.price_cost
        ) * 100).toFixed(2);
      } else {
        this.composePriceListItem.percentage_value = 0;
      }
    },
    calcolaPrezziConsigliati() {
      this.composePriceListItem.price_sell = Math.round(
          (parseFloat(this.composePriceListItem.price_cost) + parseFloat(this.selectedPriceListItem.variable)) * 2 * 1.23
      ).toFixed(2);
      this.composePriceListItem.price_wine_glass = Math.round(
          (parseFloat(this.composePriceListItem.price_cost) + parseFloat(this.selectedPriceListItem.variable)) / 2
      ).toFixed(2);
    },
    saveNewPriceListItem() {
      const isValid = this.$refs.percentage.validate();
      if (!isValid) {
        this.$dialog.notify.error("Il campo sconto è Obbligatorio, anche a 0", {color: "red"});
        return;
      }
      const params = this.composePriceListItem
      console.log('PARAMS: ', params)
      params.wine_name = this.wine.name
      params.winemaker_name = this.wine.winemaker_name
      listinoService.newListinoItemFromWineMaker(params).then(() => {
            this.$dialog.message.success("Vino associato correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
            this.composePriceListItem = {}
            this.$emit('refreshWine')
          }
      ).catch(error => {
        this.$dialog.message.error(`${error}`, {
          type: "danger",
          rounded: true,
          position: "top-right",
          color: "red",
          outlined: false,
          timeout: 2500,
        });
      }).finally(() => {
        this.closeDialog()
        this.getPriceList()
      })
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  mounted() {
    this.getPriceList()
    console.log('WINE: ', this.wine)
  },
  props: {
    icon: String,
    wine: Object
  },
  watch: {
    "composePriceListItem.price_cost"() {
      this.composePriceListItem.price_list = this.selectedPriceListItem.id
      this.composePriceListItem.wine = this.wine.id
      this.calcolaPrezziConsigliati()
    }
  },
};
</script>